export const CHANGE_PRODUCTION_QUEUE_VALUE = 'CHANGE_PRODUCTION_QUEUE_VALUE';
export const CHANGE_PRODUCTION_QUEUE_SEVERAL_VALUES = 'CHANGE_PRODUCTION_QUEUE_SEVERAL_VALUES';

export const RESET_PRODUCTION_QUEUE_STORE = 'RESET_PRODUCTION_QUEUE_STORE';
export const START_QUEUE_CONNECTION = 'START_QUEUE_CONNECTION';
export const SET_PRODUCTION_QUEUE_DATA = 'SET_PRODUCTION_QUEUE_DATA';
export const UPDATE_PRODUCTION_QUEUE_DATA = 'UPDATE_PRODUCTION_QUEUE_DATA';
export const SET_CATERING_QUEUE_DATA = 'SET_CATERING_QUEUE_DATA';
export const UPDATE_CATERING_QUEUE_DATA = 'UPDATE_CATERING_QUEUE_DATA';
export const SET_FUTURE_QUEUE_DATA = 'SET_FUTURE_QUEUE_DATA';
export const SET_GRILLED_QUEUE_DATA = 'SET_GRILLED_QUEUE_DATA';
export const SET_TIMERS_QUEUE_DATA = 'SET_TIMERS_QUEUE_DATA';
export const SET_STOCKOUT_TIMERS_QUEUE_DATA = 'SET_STOCKOUT_TIMERS_QUEUE_DATA';
export const SET_INVENTORY_DATA = 'SET_INVENTORY_DATA';
export const UPDATE_INVENTORY_DATA = 'UPDATE_INVENTORY_DATA';
export const SET_FRYERS_DATA = 'SET_FRYERS_DATA';
export const RESET_PRODUCTION_QUEUE_DATA = 'RESET_PRODUCTION_QUEUE_DATA';

export const GET_PRODUCTION_QUEUE = 'GET_PRODUCTION_QUEUE';
export const GET_PRODUCTION_QUEUE_SUCCESS = 'GET_PRODUCTION_QUEUE_SUCCESS';
export const GET_PRODUCTION_QUEUE_ERROR = 'GET_PRODUCTION_QUEUE_ERROR';

export const changeValue = (key, value) => ({
  type: CHANGE_PRODUCTION_QUEUE_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_PRODUCTION_QUEUE_SEVERAL_VALUES,
  payload: value,
});

export const setProductionQueueData = (value) => ({
  type: SET_PRODUCTION_QUEUE_DATA,
  payload: value,
});

export const updateProductionQueueData = () => ({
  type: UPDATE_PRODUCTION_QUEUE_DATA,
});

export const setCateringQueueData = (value) => ({
  type: SET_CATERING_QUEUE_DATA,
  payload: value,
});

export const updateCateringQueueData = () => ({
  type: UPDATE_CATERING_QUEUE_DATA,
});

export const setFutureQueueData = (value) => ({
  type: SET_FUTURE_QUEUE_DATA,
  payload: value,
});

export const startQueueConnection = () => ({
  type: START_QUEUE_CONNECTION,
});

export const setGrilledQueueData = (value) => ({
  type: SET_GRILLED_QUEUE_DATA,
  payload: value,
});

export const setTimersQueueData = (value) => ({
  type: SET_TIMERS_QUEUE_DATA,
  payload: value,
});

export const setStockoutTimersQueueData = (value) => ({
  type: SET_STOCKOUT_TIMERS_QUEUE_DATA,
  payload: value,
});

export const setInventoryData = (value) => ({
  type: SET_INVENTORY_DATA,
  payload: value,
});

export const setFryersData = (value) => ({
  type: SET_FRYERS_DATA,
  payload: value,
});

export const updateInventoryData = () => ({
  type: UPDATE_INVENTORY_DATA,
});

export const resetData = () => ({
  type: RESET_PRODUCTION_QUEUE_DATA,
});

export const resetStore = () => ({
  type: RESET_PRODUCTION_QUEUE_STORE,
});
