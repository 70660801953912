export const cateringStatuses = [
  {
    stage: 3,
    label: 'Ready',
    class: 'ready',
    color: '#009408',
  },
  {
    stage: 2,
    label: 'In Progress',
    class: 'go',
    color: '#DBB91D',
  },
  {
    stage: 1,
    label: 'Waiting',
    class: 'waiting',
    color: '#D872DD',
  },
];

export const cateringStatusValues = {
  INCOMPLETE: 'INCOMPLETE',
  CANCELED: 'CANCELED',
  FINISHED: 'FINISHED',
  IN_PROGRESS: 'IN_PROGRESS',
  NEW: 'NEW',
};

export const cateringReadyStatuses = [cateringStatusValues.FINISHED, cateringStatusValues.INCOMPLETE];
export const ableToFinalizeStatuses = [cateringStatusValues.NEW, cateringStatusValues.IN_PROGRESS];

export const cateringStatusColors = {
  CANCELED: {
    id: 5,
    stage: 3,
    label: 'Canceled',
    class: 'canceled',
    value: cateringStatusValues.CANCELED,
    color: '#CB444A',
  },
  FINISHED: {
    id: 4,
    stage: 3,
    label: 'Ready',
    class: 'ready',
    value: cateringStatusValues.FINISHED,
    color: '#009408',
  },
  INCOMPLETE: {
    id: 3,
    stage: 3,
    label: 'Incomplete',
    class: 'incomplete',
    value: cateringStatusValues.INCOMPLETE,
    color: '#CB444A',
  },
  IN_PROGRESS: {
    id: 2,
    stage: 2,
    label: 'In Progress',
    class: 'go',
    value: cateringStatusValues.IN_PROGRESS,
    color: '#DBB91D',
  },
  NEW: {
    id: 0,
    stage: 1,
    label: 'Waiting',
    class: 'waiting',
    value: cateringStatusValues.NEW,
    color: '#D872DD',
  },
};

export const cateringStages = [
  {
    stage: 1,
  },
  {
    stage: 2,
  },
  {
    stage: 3,
  },
];

export const demandTypes = [
  { label: 'Bus', value: 'BUS' },
  { label: 'Catering', value: 'CATERING' },
  {
    label: 'Special Event',
    value: 'SPECIAL_EVENT',
  },
];

export const workCenterOptions = [
  { label: 'Catering Work Center', value: 'CATERING_WORK_CENTER' },
  { label: 'Boards', value: 'BOARDS' },
];

export const finalizeType = {
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
};

export const orderMaterialBatchesColumns = [
  {
    label: 'Amount',
    value: 'amount',
    endOfTable: true,
    width: '84px',
    maxWidth: '84px',
  },
  {
    label: 'Waiting',
    value: 'waiting',
    width: '66px',
    maxWidth: '66px',
  },
  {
    label: 'In Progress',
    value: 'inProgress',
    width: '66px',
    maxWidth: '66px',
  },
  {
    label: 'Ready',
    value: 'ready',
    width: '66px',
    maxWidth: '66px',
  },
];
