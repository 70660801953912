import React from 'react';
import moment from 'moment';

import { ImagePreview } from '../../ImagePreview/ImagePreview';

import './EventItem.scss';

export const EventItem = ({ event, preview }) => {
  return (
    <div key={event.uuid} className="activity-history-item">
      <div className="event__time-point">
        {event.displayTime && <span className="time">{moment(event.eventTime).format('h A')}</span>}
        <div className="point"></div>
      </div>

      <div className="event__content">
        <div className="event__image-container">
          <ImagePreview image={preview} />
        </div>

        <div className="event__details">
          <span className="event__description">{event.description}</span>
          <span className="event__info" data-toggle="tooltip" data-placement="bottom" title={event.location}>
            {event.location}
          </span>
          <span className="event__info event__time">{moment(event.eventTime).format('h:mm:ss A')}</span>
        </div>
      </div>
    </div>
  );
};
