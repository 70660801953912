import React from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfiniteScroll from 'react-infinite-scroll-component';
import cx from 'classnames';

import { ReactComponent as Collapse } from '../../assets/img/icons/common/Collapse.svg';
import { ReactComponent as Expand } from '../../assets/img/icons/common/Expand.svg';
import { ReactComponent as Info } from '../../assets/img/icons/common/InfoOutlined.svg';

import { EventItem } from './EventItem/EventItem';

import './PlayerActivityHistory.scss';

export const PlayerActivityHistory = ({ events, eventPreviews, isExpended, toggleHistory, loadMore }) => {
  return (
    <div className={cx('activity-history-container', { collapsed: !isExpended })}>
      <div className="activity-history__header">
        <h5 className="activity-history__title">History</h5>
        <button className="activity-history__collapse-button" onClick={toggleHistory}>
          {isExpended ? <Collapse /> : <Expand />}
        </button>
      </div>

      {(events.data.length !== 0 || events.loading || events.scrollLoading) && (
        <div id="scrollable-activity-body" className="activity-history__body">
          <InfiniteScroll
            dataLength={events.data.length}
            next={loadMore}
            hasMore={events.existNext && !events.loading && !events.scrollLoading}
            scrollableTarget="scrollable-activity-body"
            scrollThreshold={0.99}
            style={{ overflowY: 'hidden' }}
          >
            <div id="activity-history-list" className="activity-history__list">
              {events.data.map((event) => (
                <EventItem key={event.uuid} event={event} preview={eventPreviews[event.uuid]} />
              ))}

              {(events.loading || events.scrollLoading) && (
                <div className="activity-history__next-data-loader">
                  <FontAwesomeIcon icon={faSpinner} spin />
                </div>
              )}
            </div>
          </InfiniteScroll>
        </div>
      )}

      {events.data.length === 0 && !events.loading && !events.scrollLoading && (
        <div className="activity-history__no-data">
          <Info />
          <span>No activity</span>
        </div>
      )}
    </div>
  );
};
