import { getSearchParameter } from '../../utils/urlFunctions';
import moment from 'moment';

const KITCHEN_PLAYER_PREFIX = '/kitchen-player';

export const switchKitchenPlayerTab = (tab, history) => {
  if (history.location.pathname.includes(`${KITCHEN_PLAYER_PREFIX}/${tab}`)) return;

  const transferableParams = ['restaurantUuid'];

  const searchParams = transferableParams.reduce((acc, param) => {
    const paramValue = getSearchParameter(param);

    if (paramValue) {
      const divider = acc !== '' ? '&' : '?';
      let fullSearchParam = `${param}=${paramValue}`;

      return acc + divider + fullSearchParam;
    }

    return acc;
  }, '');

  history.push(`${KITCHEN_PLAYER_PREFIX}/${tab}` + searchParams);
};

export const prepareActivityData = (events) => {
  let lastHour = null;
  let midpointAdded = false;
  let lastIndexInHour = null;

  const updatedEvents = events.map((event, index) => {
    const currentHour = moment(event.eventTime).hours();
    const currentMinutes = moment(event.eventTime).minutes();

    let displayTime = false;

    if (currentHour !== lastHour) {
      displayTime = true;
      lastHour = currentHour;
      midpointAdded = false;
    }

    if (!midpointAdded && currentMinutes >= 25 && currentMinutes <= 35) {
      displayTime = true;
      midpointAdded = true;
    }

    lastIndexInHour = index;
    return { ...event, displayTime, currentHour };
  });

  return updatedEvents.map((event, index) => {
    const isLastInHour =
      updatedEvents.findIndex((e, i) => e.currentHour === event.currentHour && i > index) === -1;
    if (isLastInHour) {
      return { ...event, displayTime: true };
    }
    return event;
  });
};
