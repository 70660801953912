import React, { useMemo } from 'react';

import { tagColors } from './constants';

import './LabeledTagsCell.scss';

const tagsColorMap = {};

export const LabeledTagsCell = ({ tags = [] }) => {
  if (!tags || tags.length === 0) return null;

  const getTagColor = (label) => {
    if (!tagsColorMap[label]) {
      tagsColorMap[label] = tagColors[Object.keys(tagsColorMap).length % tagColors.length];
    }
    return tagsColorMap[label];
  };

  const generatedTags = useMemo(
    () =>
      tags.sort().map((tag) => (
        <div
          key={tag}
          className="labeled-tag-item"
          style={{ backgroundColor: getTagColor(tag.label).backgroundColor }}
          title={`${tag.label}: ${tag.value}`}
        >
          {tag.label}{' '}
          <span style={{ color: getTagColor(tag.label).color, fontWeight: 500 }}>{tag.value}</span>
        </div>
      )),
    [tags]
  );

  return <div className="custom-cell__labeled-tags-container">{generatedTags}</div>;
};
