import moment from 'moment';

export const getOffsetLabel = (offset) => {
  const hours = Math.floor(offset / 60);
  const minutes = offset % 60;

  return `${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm' : ''}`.trim();
};

export const getNearestInterval = (time, interval) => {
  return moment(time || '00:00', 'HH:mm')
    .set({
      m: Math.ceil(moment(time, 'HH:mm').minutes() / interval) * interval,
    })
    .format('HH:mm');
};

export const getTodayStartEndDates = (timezone) => {
  const startDate = new Date(moment.tz(timezone).startOf('day').format('YYYY-MM-DDTHH:mm:ss')).getTime();
  const endDate = new Date(moment.tz(moment(), timezone).format('YYYY-MM-DDTHH:mm:ss')).getTime();

  return { startDate, endDate };
};

export const formatTimestamp = (timestamp, timezone, format = 'DD MMM • h:mm:ss A') => {
  return moment.tz(timestamp, timezone).format(format);
};

export const getTimeIntervalWithTimezone = (timeInterval, timezone) => {
  if (!timeInterval) {
    return undefined;
  }

  const offset = timezone ? moment().tz(timezone).utcOffset() : 0;
  const date = moment(`2024-01-01 ${timeInterval}`);
  return date.subtract(offset, 'minute').format('HH:mm');
};
