import React from 'react';
import { isNil } from 'lodash';

import { ColorIndicator, DateTimeCell } from '../../../../components';

import { getFormattedWaste } from '../../../../utils/dashboard';
import { dynamicSort } from '../../../../utils/sorting';

const getFormattedShare = (value) => {
  return parseFloat((value * 100).toFixed(2));
};

export const createRows = (rows, materials, sortModel) => {
  const sortedRows = dynamicSort(rows, sortModel.sortBy, sortModel.orderDirection);

  return sortedRows.map((item, index) => ({
    id: index,
    cells: [
      {
        label: (
          <ColorIndicator color={materials.find((material) => material.value === item.material)?.color} />
        ),
      },
      {
        label: item.material,
      },
      {
        label: item.value ? getFormattedWaste(item.value) : '0',
      },
      {
        label: `${getFormattedShare(item.share)}%`,
      },
    ],
  }));
};

export const createFryerCyclesRows = (rows, materials, sortModel) => {
  const sortedRows = dynamicSort(rows, sortModel.sortBy, sortModel.orderDirection);
  let preparedRows = sortedRows.filter((item) => item.total || item.perHour);

  if (preparedRows.length > 0) preparedRows = withAdditionalRowsFryerCycles(preparedRows);

  return preparedRows.map((item, index) => ({
    id: index,
    cells: [
      {
        label: !item.additional ? (
          <ColorIndicator color={materials.find((material) => material.value === item.material)?.color} />
        ) : (
          ''
        ),
      },
      {
        label: !item.additional ? (
          item.material
        ) : (
          <div className="custom-table__bold-text">{item.material}</div>
        ),
      },
      {
        label: !item.additional ? (
          getFormattedWaste(item.total)
        ) : (
          <div className="custom-table__bold-text">{getFormattedWaste(item.total)}</div>
        ),
      },
      {
        label: !item.additional ? (
          getFormattedWaste(item.perHour)
        ) : (
          <div className="custom-table__bold-text">{getFormattedWaste(item.perHour)}</div>
        ),
      },
    ],
  }));
};

export const createEntreeChutesRows = (rows, materials, sortModel) => {
  const sortedRows = dynamicSort(rows, sortModel.sortBy, sortModel.orderDirection);
  let preparedRows = sortedRows.filter((item) => item.packages || item.quantity);

  return preparedRows.map((item, index) => ({
    id: index,
    cells: [
      {
        label: (
          <ColorIndicator color={materials.find((material) => material.value === item.material)?.color} />
        ),
      },
      {
        paddingRight: 0,
        paddingLeft: 4,
        isArrow: true,
      },
      {
        label: item.material,
      },
      {
        label: getFormattedWaste(item.packages),
      },
      {
        label: getFormattedWaste(item.quantity),
      },
    ],
    items:
      item.shares?.map((share) => ({
        cells: [
          {
            label: '',
          },
          {
            label: '',
          },
          {
            label: share.packageType,
          },
          {
            label: share.packagesCount,
          },
          {
            label: share.quantity ? getFormattedWaste(share.quantity) : '0',
          },
        ],
      })) || [],
  }));
};

export const createTaskDelayRows = (rows, materials, sortModel) => {
  const sortedRows = dynamicSort(rows, sortModel.sortBy, sortModel.orderDirection);

  return sortedRows.map((item, index) => ({
    id: index,
    cells: [
      {
        label: (
          <ColorIndicator color={materials.find((material) => material.value === item.material)?.color} />
        ),
      },
      {
        label: item.material,
      },
      {
        label: item.totalDelay,
      },
      {
        label: item.highTaskDelay,
      },
      {
        label: item.lowTaskDelay,
      },
      {
        label: item.averageTaskDelay,
      },
      {
        label: item.delaysNumber,
      },
    ],
  }));
};

export const createStockOutRows = (rows, materials, sortModel) => {
  const sortedRows = dynamicSort(rows, sortModel.sortBy, sortModel.orderDirection);

  return sortedRows.map((item, index) => ({
    id: index,
    cells: [
      {
        label: (
          <ColorIndicator color={materials.find((material) => material.value === item.material)?.color} />
        ),
      },
      {
        label: item.material,
      },
      {
        label: item.time,
      },
      {
        label: item.incidentCount,
      },
    ],
  }));
};

export const createWasteRows = (rows, materials, sortModel) => {
  const sortedRows = dynamicSort(rows, sortModel.sortBy, sortModel.orderDirection);

  return sortedRows.map((item, index) => ({
    id: index,
    cells: [
      {
        label: (
          <ColorIndicator color={materials.find((material) => material.value === item.material)?.color} />
        ),
      },
      {
        label: item.material,
      },
      {
        label: item.wasteWeight ? getFormattedWaste(item.wasteWeight) : '0',
      },
      {
        label: item.cookedRatio ? `${getFormattedShare(item.cookedRatio)}%` : '0%',
      },
      {
        label: `${getFormattedShare(item.share)}%`,
      },
    ],
  }));
};

export const createContainersRows = (rows, materials, sortModel) => {
  const sortedRows = dynamicSort(rows, sortModel.sortBy, sortModel.orderDirection);

  return sortedRows.map((item, index) => ({
    id: index,
    cells: [
      {
        paddingRight: 8,
        label: (
          <ColorIndicator color={materials.find((material) => material.value === item.material)?.color} />
        ),
      },
      {
        paddingRight: 0,
        paddingLeft: 4,
        isArrow: true,
      },
      {
        label: item.material,
      },
      {
        label: item.value ? getFormattedWaste(item.value) : '0',
      },
      {
        label: `${getFormattedShare(item.share)}%`,
      },
    ],
    items:
      item.containerShares?.map((share) => ({
        cells: [
          {
            label: '',
          },
          {
            label: '',
          },
          {
            label: share.containerName,
          },
          {
            label: share.materialsCount ? getFormattedWaste(share.materialsCount) : '0',
          },
          {
            label: `${getFormattedShare(share.materialsShare)}%`,
          },
        ],
      })) || [],
  }));
};

export const createConveyorsRows = (rows, materials, sortModel, totalUsage, isInbound) => {
  let sortedRows = dynamicSort(rows, sortModel.sortBy, sortModel.orderDirection);

  if (sortedRows.length > 0) sortedRows = withAdditionalRowsConveyors(sortedRows, totalUsage, isInbound);

  return sortedRows.map((item, index) => ({
    id: index,
    unclickable: item.additional,
    cells: [
      {
        paddingRight: 8,
        label: !item.additional ? (
          <ColorIndicator color={materials.find((material) => material.value === item.material)?.color} />
        ) : (
          ''
        ),
      },
      {
        paddingRight: 0,
        paddingLeft: 4,
        isArrow: !item.additional,
      },
      {
        label: !item.additional ? (
          item.material
        ) : (
          <div className="custom-table__bold-text">{item.material}</div>
        ),
      },
      {
        label: !item.additional ? (
          getFormattedWaste(item.count)
        ) : (
          <div className="custom-table__bold-text">{getFormattedWaste(item.count)}</div>
        ),
      },
      {
        label: !item.additional ? (
          `${getFormattedShare(item.utilized)}%`
        ) : (
          <div className="custom-table__bold-text">{`${getFormattedShare(item.utilized)}%`}</div>
        ),
      },
      {
        label: !item.additional ? (
          getFormattedWaste(item.elapsedTime)
        ) : (
          <div className="custom-table__bold-text">{getFormattedWaste(item.elapsedTime)}</div>
        ),
      },
    ],
  }));
};

export const createInboundContainersRows = (rows, materials) => {
  return rows.map((item, index) => ({
    id: index,
    cells: [
      {
        label: '',
        paddingRight: 8,
      },
      {
        label: '',
        paddingRight: 0,
        paddingLeft: 4,
      },
      {
        label: item.containerName,
      },
      {
        label: <DateTimeCell date={item.inboundTime} />,
      },
      {
        label: <DateTimeCell date={item.placedTime} />,
      },
      {
        label: getFormattedWaste(item.elapsedTime),
      },
    ],
  }));
};

export const createOutboundContainersRows = (rows, materials) => {
  return rows.map((item, index) => ({
    id: index,
    cells: [
      {
        label: '',
        paddingRight: 8,
      },
      {
        label: '',
        paddingRight: 0,
        paddingLeft: 4,
      },
      {
        label: item.containerName,
      },
      {
        label: <DateTimeCell date={item.removedTime} />,
      },
      {
        label: <DateTimeCell date={item.outboundTime} />,
      },
      {
        label: getFormattedWaste(item.elapsedTime),
      },
    ],
  }));
};

export const getChartData = (content, title) => {
  return [
    ['Name', 'Waste', { type: 'string', role: 'tooltip', p: { html: true } }],
    ...content.map((item) => {
      const formattedValue = getFormattedWaste(item.value);
      return [
        item.material,
        formattedValue,

        `<div class="chart-tooltip"><span>${
          item.material
        }</span>${`<div>${title}: <b>${formattedValue} (${getFormattedShare(
          item.share
        )} %)</b></div>`}</div>`,
      ];
    }),
  ];
};

export const getChartDataByKey = (content, key, title) => {
  return [
    ['Name', 'Waste', { type: 'string', role: 'tooltip', p: { html: true } }],
    ...content.map((item) => {
      const formattedValue = getFormattedWaste(item[key]);
      return [
        item.material,
        formattedValue,

        `<div class="chart-tooltip"><span>${
          item.material
        }</span>${`<div>${title}: <b>${formattedValue}</b></div>`}</div>`,
      ];
    }),
  ];
};

export const getEmptyChartData = () => {
  return [
    ['Name', ''],
    ['Empty', 1],
  ];
};

export const formatSecondsToMinutes = (value) => {
  const minutes = value / 60;

  return parseFloat(minutes.toFixed(2));
};

export const formatMsToMinutes = (value) => {
  const minutes = value / 1000 / 60;

  return parseFloat(minutes.toFixed(2));
};

export const formatMsToSeconds = (value, fixed = 2) => {
  const minutes = value / 1000;

  return parseFloat(minutes.toFixed(fixed));
};

export const withAdditionalRowsFryerCycles = (rows) => {
  let maxTotal;
  let minTotal;
  let maxPerHour;
  let minPerHour;
  let sumTotal = 0;
  let sumPerHour = 0;

  const rowsCopy = [...rows];

  rows.forEach((row) => {
    if (isNil(maxTotal) || row.total > maxTotal) maxTotal = row.total;
    if (isNil(minTotal) || row.total < minTotal) minTotal = row.total;
    if (isNil(maxPerHour) || row.perHour > maxPerHour) maxPerHour = row.perHour;
    if (isNil(minPerHour) || row.perHour < minPerHour) minPerHour = row.perHour;
    sumTotal += row.total;
    sumPerHour += row.perHour;
  });

  const avgTotal = sumTotal / rows.length;
  const avgPerHour = sumPerHour / rows.length;

  rowsCopy.push({
    material: 'High Value',
    total: maxTotal,
    perHour: maxPerHour,
    additional: true,
  });

  rowsCopy.push({
    material: 'Low Value',
    total: minTotal,
    perHour: minPerHour,
    additional: true,
  });

  rowsCopy.push({
    material: 'Average Value',
    total: avgTotal,
    perHour: avgPerHour,
    additional: true,
  });

  return rowsCopy;
};

export const withAdditionalRowsConveyors = (rows, totalUsage, isInbound) => {
  let sumContainers = 0;
  let sumUtilized = 0;
  let sumElapsedTime = 0;

  const rowsCopy = [...rows];

  rows.forEach((row) => {
    sumContainers += row.count;
  });

  if (totalUsage && totalUsage.inbound && isInbound) {
    sumUtilized = totalUsage.inbound.utilized;
    sumElapsedTime = totalUsage.inbound.elapsedTime;
  }

  if (totalUsage && totalUsage.outbound && !isInbound) {
    sumUtilized = totalUsage.outbound.utilized;
    sumElapsedTime = totalUsage.outbound.elapsedTime;
  }

  rowsCopy.push({
    material: 'Summary:',
    count: sumContainers,
    utilized: sumUtilized,
    elapsedTime: sumElapsedTime,
    additional: true,
  });

  return rowsCopy;
};
