import { getScales, updateScales, getRules, addRule, updateRule, deleteRule } from '../../api/adjustment';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_ADJUSTMENT_VALUE = 'CHANGE_ADJUSTMENT_VALUE';
export const CHANGE_ADJUSTMENT_SEVERAL_VALUES = 'CHANGE_ADJUSTMENT_SEVERAL_VALUES';
export const RESET_ADJUSTMENT_STORE = 'RESET_ADJUSTMENT_STORE';

export const GET_ADJUSTMENT = 'GET_ADJUSTMENT';
export const GET_ADJUSTMENT_SUCCESS = 'GET_ADJUSTMENT_SUCCESS';
export const GET_ADJUSTMENT_ERROR = 'GET_ADJUSTMENT_ERROR';

export const UPDATE_ADJUSTMENT = 'UPDATE_ADJUSTMENT';
export const UPDATE_ADJUSTMENT_SUCCESS = 'UPDATE_ADJUSTMENT_SUCCESS';
export const UPDATE_ADJUSTMENT_ERROR = 'UPDATE_ADJUSTMENT_ERROR';

export const GET_ADJUSTMENT_RULES = 'GET_ADJUSTMENT_RULES';
export const GET_ADJUSTMENT_RULES_SUCCESS = 'GET_ADJUSTMENT_RULES_SUCCESS';
export const GET_ADJUSTMENT_RULES_ERROR = 'GET_ADJUSTMENT_RULES_ERROR';

export const ADD_ADJUSTMENT_RULE = 'ADD_ADJUSTMENT_RULE';
export const ADD_ADJUSTMENT_RULE_SUCCESS = 'ADD_ADJUSTMENT_RULE_SUCCESS';
export const ADD_ADJUSTMENT_RULE_ERROR = 'ADD_ADJUSTMENT_RULE_ERROR';

export const UPDATE_ADJUSTMENT_RULE = 'UPDATE_ADJUSTMENT_RULE';
export const UPDATE_ADJUSTMENT_RULE_SUCCESS = 'UPDATE_ADJUSTMENT_RULE_SUCCESS';
export const UPDATE_ADJUSTMENT_RULE_ERROR = 'UPDATE_ADJUSTMENT_RULE_ERROR';

export const DELETE_ADJUSTMENT_RULE = 'DELETE_ADJUSTMENT_RULE';
export const DELETE_ADJUSTMENT_RULE_SUCCESS = 'DELETE_ADJUSTMENT_RULE_SUCCESS';
export const DELETE_ADJUSTMENT_RULE_ERROR = 'DELETE_ADJUSTMENT_RULE_ERROR';

export const changeValue = (key, value) => ({
  type: CHANGE_ADJUSTMENT_VALUE,
  payload: {
    key,
    value,
  },
});

export const getScalesAction = (function () {
  let prevController;

  return (uuid, params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_ADJUSTMENT });
      try {
        const response = await getScales(uuid, params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_ADJUSTMENT_SUCCESS, payload: results });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.error(error);
          dispatch({ type: GET_ADJUSTMENT_ERROR });
        }
      }
    };
  };
})();

export const updateScalesAction = (uuid, params) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_ADJUSTMENT });
    try {
      const response = await updateScales(uuid, { scales: params });
      const { success } = response;
      if (success) {
        dispatch(getScalesAction(uuid, { materialUuids: getState().adjustment.material.join() }));
        dispatch({ type: UPDATE_ADJUSTMENT_SUCCESS });
      }
    } catch (e) {
      dispatch({ type: UPDATE_ADJUSTMENT_ERROR });
    }
  };
};

export const getRulesAction = (function () {
  let prevController;

  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_ADJUSTMENT_RULES });
      try {
        const response = await getRules(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_ADJUSTMENT_RULES_SUCCESS, payload: results });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.error(error);
          dispatch({ type: GET_ADJUSTMENT_RULES_ERROR });
        }
      }
    };
  };
})();

export const addRuleAction = (data, actions = {}) => {
  return async (dispatch, getState) => {
    dispatch({ type: ADD_ADJUSTMENT_RULE });
    try {
      const response = await addRule(data);
      const { success } = response;
      if (success) {
        getState().adjustment.restaurantUuid === data.restaurantUuid &&
          dispatch(
            getRulesAction({
              restaurantUuid: getState().adjustment.restaurantUuid,
              materialUuids: getState().adjustment.material.join(),
            })
          );
        dispatch({ type: ADD_ADJUSTMENT_RULE_SUCCESS });
        actions.onSuccess && actions.onSuccess();
      }
    } catch (e) {
      dispatch({ type: ADD_ADJUSTMENT_RULE_ERROR });
      actions.onError && actions.onError(e.message);
    }
  };
};

export const updateRuleAction = (uuid, data, actions = {}) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_ADJUSTMENT_RULE });
    try {
      const response = await updateRule(uuid, data);
      const { success } = response;
      if (success) {
        getState().adjustment.restaurantUuid === data.restaurantUuid &&
          dispatch(
            getRulesAction({
              restaurantUuid: getState().adjustment.restaurantUuid,
              materialUuids: getState().adjustment.material.join(),
            })
          );
        dispatch({ type: UPDATE_ADJUSTMENT_RULE_SUCCESS });
        actions.onSuccess && actions.onSuccess();
      }
    } catch (e) {
      dispatch({ type: UPDATE_ADJUSTMENT_RULE_ERROR });
      actions.onError && actions.onError(e.message);
    }
  };
};

export const deleteRuleAction = (uuid, actions = {}) => {
  return async (dispatch, getState) => {
    dispatch({ type: DELETE_ADJUSTMENT_RULE });
    try {
      const response = await deleteRule(uuid);
      const { success } = response;
      if (success) {
        dispatch(
          getRulesAction({
            restaurantUuid: getState().adjustment.restaurantUuid,
            materialUuids: getState().adjustment.material.join(),
          })
        );
        dispatch({ type: DELETE_ADJUSTMENT_RULE_SUCCESS });
        actions.onSuccess && actions.onSuccess();
      }
    } catch (e) {
      dispatch({ type: DELETE_ADJUSTMENT_RULE_ERROR });
      actions.onError && actions.onError(e.message);
    }
  };
};

export const changeSeveralValues = (value) => ({
  type: CHANGE_ADJUSTMENT_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_ADJUSTMENT_STORE,
});
